import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import { withPrefix } from 'gatsby';

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  tags,
  title,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
    <div style = {{ backgroundImage: `url(${withPrefix(cover)})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", display: "table"}}  
         className="full-width"
    >
      <div style={{display: "table-cell", verticalAlign: "middle"}}>
      <h1 className='title title-bg-image is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      </div>
    </div>
      <PostContent content={content} />
      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className='taglist'>
          {(tags && tags.length)
            ? tags.map(tag => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null}
        </ul>
      </div>
      <hr />
    </div>
  )
}

export default ArticleTemplate
